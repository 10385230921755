import {createContext,useState} from 'react';
import modelo from './modelo';




export default  function AppC({ children }){
  let proyectosList = modelo.proyectos;
  const habilidades = modelo.habilidades;
  const certificadosList = modelo.certificados;

    const [color,setColor] = useState('');
    const [proyectos,setProyectos] = useState(proyectosList);
    const [proyectosMostrados,setProyectosMostrados] = useState(proyectos);
    const [certificadosMostrados,setCertificadosMostrados] = useState(certificadosList);

    return (            
            <AppContext.Provider value={{
              color,
              setColor,
              proyectos,
              setProyectos,
              proyectosMostrados,
              setProyectosMostrados,
              habilidades,
              certificadosList,
              certificadosMostrados,
              setCertificadosMostrados
              }}>
                {children}
            </AppContext.Provider>  
    );
}

export const AppContext = createContext();