import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AppContext from './Componentes/appcontext';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

// Estilos globales
import './globales.css'

// Header
import Nav from './Componentes/nav';
import CambiarColor from './Componentes/cambiarColor';

// Inicio
import Inicio from './Componentes/inicio/index';

// Portafolio
import Portafolio from './Componentes/portafolio/index';
import DetallesPortafolio from './Componentes/detallesPortafolio/index';

// Certificados
import Certificados from './Componentes/certificados/index';

// Certificados
import SobreMi from './Componentes/sobreMi/index';

// Contacto
import ContactoPage from './Componentes/contacto/index';

// Footer
import Footer from './Componentes/footer';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AppContext>
      <div className="container">
        <Nav/>
        <CambiarColor />

        <Switch>
          <Route exact path="/">
             <Inicio />
          </Route>
          <Route path="/portafolio/:id">
                <DetallesPortafolio />
          </Route>          
          <Route path="/portafolio">
                <Portafolio/>
          </Route>
          <Route path="/certificados">
                <Certificados/>
          </Route>   
          <Route path="/sobre-mi">
                <SobreMi/>
          </Route>  
          <Route path="/contacto">
                <ContactoPage/>
          </Route>                   
        </Switch>
        <Footer />
      </div>
      </AppContext>
    </Router>
    <style jsx='true'>{`
                .container {
                min-height: 100vh;
                padding: 0 0.5rem;
                padding-top: 54px;
                display: flex;
                flex-direction: column;
                align-items: center;
                
                }

                main {
                flex: 1;
                width:95%;
                }

                footer {
                width: 100%;
                height: 100px;
                border-top: 1px solid #eaeaea;
                display: flex;
                justify-content: center;
                align-items: center;
                }

                footer img {
                margin-left: 0.5rem;
                }

                footer a {
                display: flex;
                justify-content: center;
                align-items: center;
                }
         `}</style>
        <style jsx='true' global="true">{`
            html,
            body {
            padding: 0;
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                sans-serif;
            }

            * {
            box-sizing: border-box;
            }
            button{
              border:none;
              font-size:1rem;
              cursor: pointer;
            }
        `}</style>
  </React.StrictMode>,
  document.getElementById('root')

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();