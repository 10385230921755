const proyectos = [
    {id:1,nombre: 'PLATAFORMA PARA DEALERS',
    descripcion:'Esta plataforma web permite el manejo de inventario de vehiculos online y en tiempo real. Hace posible que el cliente vea todos los carros disponibles y realizar cotizaciones, solicitud de prestamos, entre muchas cosas más.',
    categoria:'Paginas webs',
    imagenes:[
        {ruta:'/assets/img/proyectos/plataforma-dealers01.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers02.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers03.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers04.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers05.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers09.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers06.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers07.png'},
        {ruta:'/assets/img/proyectos/plataforma-dealers08.png'},
    ]
    },
    {id:2,nombre: 'RDL SOFT',
    descripcion:'Sistema de contabilidad desarrollado. El sistema tiene el objetivo de manejar todo el proceso contable de las empresa. Aparte de eso consta con algunas funciones más como por ejemplo, personalizar el color del mismo. Una vez cargado en el navegador no vuelve a recargar la pagina. solicita de forma asincrona los recursos',
    categoria:'Sistemas',
    imagenes:[
        {ruta:'/assets/img/proyectos/rdl-soft.png'},
        {ruta:'/assets/img/proyectos/rdl-soft02.png'},
        {ruta:'/assets/img/proyectos/rdl-soft03.png'},
        {ruta:'/assets/img/proyectos/rdl-soft04.png'},
        {ruta:'/assets/img/proyectos/rdl-soft05.png'},
        {ruta:'/assets/img/proyectos/rdl-soft06.png'},
        {ruta:'/assets/img/proyectos/rdl-soft07.png'},
        {ruta:'/assets/img/proyectos/rdl-soft08.png'},
        {ruta:'/assets/img/proyectos/rdl-soft09.png'},
    ]
    },
    {id:3,nombre: 'APP DE FINANZAS',
    descripcion:'Esta app permite administrar los ingresos y egresos. Está pensada para administar las finanzas personales.  Utiliza de las apis más modernas de html5 y javascript que le permiten convertirse en una app PWA, lo cual le permite seguir funcionando aún luego de perder acceso a internet; guardando los datos y enviándolos  al backend cuando tenga acceso a internet.',
    categoria:'Aplicaciones',
    imagenes:[
        {ruta:'/assets/img/proyectos/rdl-rar.png'}]
    },
    {id:4,nombre: 'SISTEMA ECOMMERCE',
    descripcion:'Es un sistema creado particularmente para un proyecto en espesifico. Posee una tienda online, un cms, un chat privado entre socios, entre otras cosas. Creado desde cero y personalizado a gusto. Las compras son realizadas por socios y ellos mismo pueden asociar más personas para al ﬁnal poder general ganancias, incluso sin realizar ninguna compra.',
    categoria:'Tiendas Virtuales',
    imagenes:[
        {ruta:'/assets/img/proyectos/sistema-ecommerce.png'},
        {ruta:'/assets/img/proyectos/sistema-ecommerce02.png'},
        {ruta:'/assets/img/proyectos/sistema-ecommerce03.png'},
        {ruta:'/assets/img/proyectos/sistema-ecommerce04.png'},
        {ruta:'/assets/img/proyectos/sistema-ecommerce05.png'},
    ]
    },
    {id:5,nombre: 'PLATAFORMA EDUCATIVA',
    descripcion:'Es una plataforma para compartir conocimiento para aquellos que lo necesitan. Puede funcionar sin internet debido a tecnicas de pwa implementadas.',
    categoria:'Paginas webs',
    imagenes:[
        {ruta:'/assets/img/proyectos/plataforma-educativa01.png'}]
    },
    {id:6,nombre: 'FLEET LINE AUTOMOTRIZ',
    descripcion:'Página web para la empresa ﬂeet line. Está realizada bajo la modalidad MVC y posee un cms desarrollado desde cero. Dentro del cms se pueden administrar el estado de los vehículos recibidos en la empresa, entre otras funciones más.',
    categoria:'Paginas webs',
    imagenes:[
        {ruta:'/assets/img/proyectos/fleetline01.png'}]
    },
    {id:7,nombre: 'SISTEMA  DE  PRESUPUESTO EMPRESARIAL',
    descripcion:'Este es un sistema muy completo y complejo. El backend esta creado sin framework, y en la parte del frontend utilizamos vanilla js, bootstrap, entre otros. El sistema consiste en controlar las entradas y salidas de efectivo de la empresa, utilizando la metodología de crear presupuestos para que el presidente de la empresa pueda verlo y revisar, a la vez que decide cuales aprueba y cuales no. Aparte de controlar los presupuestos, genera diversos informes con gráficos para ayudar a comprender el balance de situación de la empresa. Es multi-emplesa, osea, que puede registrar múltiples empresas y controlar de manera independiente cada una. El sistema usa la api de gps para registrar y controlar desde donde acceden a ella.',
    categoria:'Paginas webs',
    imagenes:[
        {ruta:'/assets/img/proyectos/sistema-presupuestos.png'},
        {ruta:'/assets/img/proyectos/sistema-presupuestos02.png'},
        {ruta:'/assets/img/proyectos/sistema-presupuestos03.png'},
        {ruta:'/assets/img/proyectos/sistema-presupuestos04.png'},
    ]
    },   
    {id:8,nombre: 'ADMINISTRADOR DE PROYECTOS Y TAREAS',
    descripcion:'Esta plataforma fue permite crear proyectos y asignar tareas a los empleados. Permite dar seguimiento a los avance de proyectos o cumplimiento de tareas a travez de reportes, tablero scrum, entre otros. Tiene tecnologia de pwa, es decir se puede instalar en celulares y disparar notificaciones, etc. Tiene la funcion de enviar correos para nuevas tareas, asignaciones vencidas, comentario de asignacion, entre muchas más.',
    categoria:'Sistemas',
    imagenes:[
        {ruta:'/assets/img/proyectos/rdl-task.png'},
        {ruta:'/assets/img/proyectos/rdl-task02.png'},
        {ruta:'/assets/img/proyectos/rdl-task03.png'},
        {ruta:'/assets/img/proyectos/rdl-task04.png'},
        {ruta:'/assets/img/proyectos/rdl-task05.png'},
    ]
    },      
];

const certificados = [
    {id:1,nombre: 'React y Firebase',categoria:'Desarrollo web',imagen:'/assets/img/certificados/react-firebases.jpg'},
    {id:2,nombre: 'Master en Full Stack',categoria:'Desarrollo web',imagen:'/assets/img/certificados/master-full-stack.jpg'},
    {id:3,nombre: 'Master en JavaScript',categoria:'Desarrollo web',imagen:'/assets/img/certificados/master-javascript.jpg'},
    {id:4,nombre: 'Master en css',categoria:'Desarrollo web',imagen:'/assets/img/certificados/master-css.jpg'},
    {id:5,nombre: 'Diseño de páginas web',categoria:'Desarrollo web',imagen:'/assets/img/certificados/diseno-de-paginas-web.jpg'},
    {id:6,nombre: 'Introducción a la programación',categoria:'Desarrollo web',imagen:'/assets/img/certificados/curso-de-programacion.jpg'},
    {id:7,nombre: 'Finder',categoria:'Tecnologia digital',imagen:'/assets/img/certificados/finder.jpg'},
    {id:8,nombre: 'Seguridad informática',categoria:'Cibersegurida',imagen:'/assets/img/certificados/seguridad-informatica.jpg'},
    {id:9,nombre: 'Técnico en informática',categoria:'Informatica',imagen:'/assets/img/certificados/tecnico-en-informatica.jpg'},
    {id:10,nombre: 'Diplomado en sistemas',categoria:'Informatica',imagen:'/assets/img/certificados/tecnico-en-sistemas-informaticos.jpg'}
];

const habilidades =  [
    {id:1,nombre:'REACT'},
    {id:2,nombre:'NODE'},
    {id:3,nombre:'MONGO'},
    {id:4,nombre:'FIREBASES'},
    {id:5,nombre:'PHP'},
    {id:6,nombre:'LARAVEL'},
    {id:7,nombre:'CODEIGNITER'},
    {id:8,nombre:'JAVASCRIPT'},
    {id:9,nombre:'HTML'},
    {id:10,nombre:'CSS'},
    {id:11,nombre:'BOOTSTRAP'},
    {id:12,nombre:'SQL SERVER'},
    {id:13,nombre:'MYSQL'},
    {id:14,nombre:'GIT'},
    {id:15,nombre:'GITHUB'},
    {id:16,nombre:'LINUX'},
    {id:17,nombre:'JSON'},
    {id:18,nombre:'APIS REST'},
    {id:19,nombre:'MERN'},
    {id:20,nombre:'EXPRESS'},
];

const data = {
    proyectos,
    habilidades,
    certificados
};

export default data;