import {useContext, useEffect} from 'react';
import {AppContext} from '../appcontext';
import {Link} from 'react-router-dom';


export default function Proyectos() {
    const {color,proyectosMostrados, setProyectosMostrados, proyectos} = useContext(AppContext);

    useEffect(() => {
        //Scroll a 0
        document.querySelector('html').scrollTop = 0;
        setProyectosMostrados(proyectos);
    }, [proyectos, setProyectosMostrados]);


    return(
        <>
        <div className="proyectos">
            <h2>Proyectos:</h2>
            {
                proyectosMostrados.map(e=>{

                    return (
                        <div className="card" key={e.id}>
                            <Link to={'/portafolio/'+e.id}>
                                <h3>{e.nombre}</h3>
                                <img  src={e.imagenes[0].ruta} alt={e.nombre}/>
                            </Link>
                        </div>)
                
                })
            }
        </div>
        <style jsx="true">{`
                div.proyectos{
                    display:flex;
                    width:85%;
                    flex-wrap:wrap;
                    min-height:685px;
                    align-content: flex-start;
                }
                div.proyectos .card{
                    min-width:270px;
                    flex-basis:29%;
                    margin:20px;
                    padding:10px;
                    display:flex;
                    flex-direction:column;
                    text-align:center;
                    box-shadow: 0 16px 28px #80808047;
                    border-radius:5px;
                    cursor:pointer;
                }
                div.proyectos .card:hover{
                    background:${color}54;
                }
                div.proyectos img{
                    width:100%;
                    border-radius: 10px;
                }
                div.proyectos h2{
                    width:100%;
                    font-size:2p;
                    margin-left:15px;
                }
                div.proyectos .card h3{
                    font-weight: 100;
                    margin: 2px;
                }
                @media only screen and (max-width: 727px) {
                    div.proyectos {
                        width: 100%;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        justify-content: space-between;
                    }
                    div.proyectos .card {
                        min-width: 164px;
                        flex-basis: 27%;
                        margin: 4px;
                        padding: 0px;
                    }
                }
                @media only screen and (min-width: 768px) and (max-width: 960px){
                    div.proyectos {
                        width: 100%;
                    }
                    div.proyectos .card {
                        flex-basis: 44%;
                    }
                    
                }
            `}</style>
        </>
    )
};
