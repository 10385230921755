import {useContext, useEffect} from 'react';
import {AppContext} from '../appcontext';
import Modal from './../modal';


export default function Listado() {
    
    const {color,certificadosMostrados, setCertificadosMostrados, certificadosList} = useContext(AppContext);

    const mostrar = (ruta)=>{
            document.querySelector('div.modal img').src = ruta;
            document.querySelector('div.modal').style.display = 'block';
    }
    useEffect(() => {

        document.querySelector('html').scrollTop = 0;
        setCertificadosMostrados(certificadosList);

    }, [certificadosList,setCertificadosMostrados]);

    return(
        <>
        <div className="certificados">
            <h2>Certificados:</h2>
            {
                certificadosMostrados.map(c=>{

                    return (
                            <div className="card" key={c.id}>
                                <a href="#1" onClick={e=>mostrar(c.imagen)}>
                                    <h3>{c.nombre}</h3>
                                    <img  src={c.imagen} alt={c.nombre}/>
                                </a>
                            </div>
                            )
                        

                })
            }
        </div>
        <Modal/>
        <style jsx="true">{`
                div.certificados{
                    display:flex;
                    width:85%;
                    flex-wrap:wrap;
                    min-height:685px;
                    align-content: flex-start;
                }
                div.certificados .card{
                    min-width:270px;
                    flex-basis:29%;
                    margin:20px;
                    padding:10px;
                    display:flex;
                    flex-direction:column;
                    text-align:center;
                    box-shadow: 0 16px 28px #80808047;
                    border-radius:5px;
                    cursor:pointer;
                    max-height: 300px;
                    overflow: hidden;
                }
                div.certificados .card:hover{
                    background:${color}54;
                }
                div.certificados img{
                    width:100%;
                    border-radius: 10px;
                }
                div.certificados h2{ 
                    width:100%;
                    font-size:2p;
                    margin-left: 17px;
                }
                div.certificados .card h3{
                    font-weight: 100;
                    margin: 2px;
                }
                @media only screen and (max-width: 727px) {
                    div.certificados {
                        width: 100%;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        justify-content: space-between;
                    }
                    div.certificados .card {
                        min-width: 164px;
                        flex-basis: 27%;
                        margin: 4px;
                        padding: 0px;
                        max-height: 150px;
                        overflow: hidden;
                    }
                    div.certificados .card h3{
                        font-size:.7rem;
                        margin: 4px 0;
                    }
                }
                @media only screen and (min-width: 768px) and (max-width: 960px){
                    div.certificados {
                        width: 100%;
                    }
                    div.certificados .card {
                        flex-basis: 44%;
                    }
                    
                }

            `}</style>
        </>
    )
};
