import Header from './header'
import Contacto from './../contacto'
export default function ContactoPage() {
    return(
        <>
            <div className="contenedor">
                <Header />
                <Contacto />
            </div>
            <style jsx='true'>{`
                div.contenedor{
                    width:100%;
                }
                p.contacto-descripcion{
                    display:none;
                }
                .contenedor>h2{
                    display:none;
                }
            `}</style>
        </>
    )
};
