import Bio from './bio';
import Proyectos from './proyectos';
import Habilidades from './habilidades';
import Cotacto from '../contacto';

export default function Inicio(){
    return(
        <>
            <Bio/>
            <Proyectos/>
            <Habilidades/>
            <Cotacto/>
        </>
    )
}
