
import React from 'react';
import {useContext} from 'react';
import {AppContext} from './appcontext';

export default function Header() {

    const {color} = useContext(AppContext);

    return (
        <>
            <header>
                <div>
                    <h1>Hola, Soy <br />
                        <b>Ronald De Leon</b>
                    </h1>
                    <p>Desarrollador web Full Stack con 6+ años de experiencia</p>
                </div>
            </header>
            <style jsx="true">{`
                header{
                    display:flex;
                    
                }
                header>div{
                    flex-basis:100%; 
                }
                header h1{
                    font-size: 3rem;
                    margin-bottom:0;
                }
                header  p{
                    /* color:#fff; */
                }
                header>div>h1>b{
                    color:${color};
                }
                a.contact{
                    line-height: 45px;
                    background: ${color};
                    padding: 12px 31px;
                    border-radius: 25px;
                    color: #fff;
                    box-shadow: -4px 17px 22px 0 ${color}5c;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    margin-right:20px;
                }

            `}</style>
        </>
    )
};
