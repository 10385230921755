import Contenido from './Contenido';
import Header from './header';
import Filtros from './filtros';


export default function Index() {
    return (
        <>
            <div className="contenedor">
                <Header />
                <Filtros />
                <Contenido />
            </div>
            <style jsx="true">{`
               .contenedor{
                   width:100%;
                   display:flex;
                   flex-wrap: wrap;
               }
            `}</style>
        </>
    )
};
