import Modal from './../modal';

export default function Slider({proyecto}) {
    let contador1 = 1;
    let contador2 = 1;

    const mostrar = (ruta)=>{
        document.querySelector('div.modal img').src = ruta;
        document.querySelector('div.modal').style.display = 'block';
    }

    return(
        <>
        <div className="slider"> 
                 <ul className="slider">
                    {
                        proyecto.imagenes.map(i=>{

                            return (
                                <li key={i.ruta} id={contador1++}>
                                    <img src={i.ruta} onClick={()=>mostrar(i.ruta)} alt="Captura del proyecto" />
                                </li>
                            )
                        })
                        
                    }

                </ul>
                
                <ul className="menu">
                {
                        proyecto.imagenes.map(i=>{
                            return (
                                <li key={i.ruta}>
                                    <a href={'#'+contador2}>{contador2++}</a>
                                </li>
                            )
                        })
                    }

                </ul>
        </div>
        <Modal />
        <style jsx="true">{`
            ul, li {
                padding: 0;
                margin: 0;
                list-style: none;
            }
            
            ul.slider{
              position: relative;
              width: 50vw;
              height: 400px;
            }
            
            ul.slider li {
                position: absolute;
                left: 0px;
                top: 0px;
                display:none;
                width: inherit;
                height: inherit;
                transition: all .5s;
                background:#fff;
            }
            
            ul.slider li img{
              width: 100%;
              height: 100%;
              cursor:pointer;
            }
            
            ul.slider li:first-child {
                display:block;
                animation-name:test;
            }
            
            ul.slider li:target {
                display:block;
            }
            
            .menu{
              text-align: center;
              margin: 20px;
            }
            
            .menu li{
              display: inline-block;
              text-align: center;
            }
            
            .menu li a{
                display: inline-block;
                color: white;
                text-decoration: none;
                background-color: grey;
                padding: 10px;
                width: 41px;
                height: 41px;
                font-size: 20px;
                border-radius: 100%;
                margin: 5px;
            }
            @keyframes test {
                    from {
                        transform: scale3d(0, 0, 0);
                    }
                    to{
                        transform: scale3d(1, 1, 1);
                    }
            }
            @media only screen and (max-width: 727px) {
                ul.slider{
                    position: relative;
                    width: 97vw;
                    height: 300px;
                  }
            }
        `}</style>
        </>
    )    
};
