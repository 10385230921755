import {useContext} from 'react';
import {AppContext} from './../appcontext';

export default function Habilidades() {

    const {habilidades} = useContext(AppContext);

    return(
        <>
        <h2>Habilidades y tecnologias</h2>
        <div className="habilidades">
            <div className="cuerpo">
                    {
                        habilidades.map(a=>{
                            return(
                                <div className="habilidad" key={a.id}>
                                    <h3>{a.nombre}</h3>
                                    <div className="porciento">
                                        <div className="nivel"></div>
                                        <div className="base"></div>
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <style jsx="true">{`
            div.habilidades{
                width:96%;
            }
            div.habilidades div.cuerpo{
                display: flex;
                flex-wrap:wrap;
                justify-content: space-between;
                gap: 10px;
            }
            div.habilidades div.habilidad{
                box-shadow: 0 0 6px lightgrey;
                padding:5px;
                border-radius:3px;
                margin-bottom:10px;
                align-items: center;
                display: inline-flex;
                flex: 1 1 23%;
            }
            div.habilidades div.porciento{
                position:relative;
            }
            div.habilidades div.base{
                background: #f1f1f1;
                width: 100%;
                height: 5px;
                border-radius: 50px;
            }
            div.habilidades div.nivel{
                width: 100%;
                height: 5px;
                position: absolute;
                border-radius: 50px;
                text-align: right;
                color: #fff;
                font-size: .8rem;
            }
            div.habilidades div > h3{
                margin:5px;
                font-weight: 300;
            }
            @media only screen and (max-width: 727px) {
                
                div.habilidades div.habilidad{
                    font-size: small;
                }
                div.habilidades div.nivel{
                    height: 4px;

                }
                div.habilidades div.base{
                    height: 4px;
                }
            }
        `}</style>
        </>
    )    
};
