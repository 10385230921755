
export default function Modal(params) {
    return(
        <>
        <div className="modal" onClick={e=>e.target.classList.contains('modal') ? e.target.style.display = 'none':''}>
            <img src="" alt=""/>
        </div>
        <style jsx="true">{`
                div.modal{
                    position:fixed;
                    top:0;
                    left:0;
                    height:100vh;
                    width:100vw;
                    background:#0000009c;
                    z-index:1000;
                    display:none;
                    overflow: auto;
                }
                div.modal img{
                    margin: 0 auto;
                    animation-name: rubberBand;
                    display: block;
                    margin-top: 5vh;
                    max-width:70%;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    animation-iteration-count: 1;
                }

                @keyframes rubberBand {
                    from {
                        transform: scale3d(1, 1, 1);
                    }

                    30% {
                        transform: scale3d(1.25, 0.75, 1);
                    }

                    40% {
                        transform: scale3d(0.75, 1.25, 1);
                    }

                    50% {
                        transform: scale3d(1.15, 0.85, 1);
                    }

                    65% {
                        transform: scale3d(.95, 1.05, 1);
                    }

                    75% {
                        transform: scale3d(1.05, .95, 1);
                    }

                    to {
                        transform: scale3d(1, 1, 1);
                    }
                    }
                    @media only screen and (max-width: 727px) {
                        div.modal img{
                            max-width: 87%;
                        }
                    }
                    @media only screen and (min-width: 768px) and (max-width: 960px){
                        div.modal img {
                            max-width: 97%;
                        }
                    }
        `}</style>
        </>
    )
};
