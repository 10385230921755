
import React from 'react';
import {useContext} from 'react';
import {AppContext} from './../appcontext';


export default function Header() {

    const {color} = useContext(AppContext);

    return (
        <>
            <header>
                <div>
                    <h1>
                        <b>Portafolio</b>
                    </h1>
                    <p>Aqui muestro algunos de los proyectos que he tenido la oportunidad de realizar</p>
                </div>
            </header>
            <style jsx="true">{`
                header{
                    display:flex;
                    background: linear-gradient(-15deg,${color} 35%,black 40%);
                    height: 200px;
                    padding: 20px;
                    box-shadow: 1px 5px 16px ${color}6e;
                    flex-basis:100%;
                    margin-bottom:20px;
                }
                header>div{
                    flex-basis:50%;
                }
                header h1{
                    font-size: 3rem;
                    margin-bottom:0;
                    color:#FFF;
                }
                header  p{
                    color:#fff;
                }
                header>div>h1>b{
                    color:${color};
                }

            `}</style>
        </>
    )
};
