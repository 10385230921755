import {useContext} from 'react';
import {AppContext} from './../appcontext';

export default function Filtros() {

    const {proyectos,setProyectosMostrados, color} = useContext(AppContext);

    const categorias = [...new Set(proyectos.map(p=> p.categoria))]; 


    const filtrar = (e)=>{
        e.preventDefault();
        if (e.target.getAttribute('cat') !== 'todos') {
            
            let categoria = e.target.getAttribute('cat');
            let mostrar = proyectos.filter(p=> p.categoria === categoria);
            setProyectosMostrados(mostrar);
        }else{
            setProyectosMostrados(proyectos);
        }
        
    };

    return (
        <>
            <div className="filtros">
                <h2>Categorias</h2>
                <ul>
                    {
                    categorias.map(c=>{

                        return ( <li key={c} id={c}><a href="#1" cat={c}  onClick={ (e)=>{filtrar(e) }}>{c}</a></li>)


                        })  
                    } 
                    <li id="todos"><a href="#todos" cat="todos"  onClick={ (e)=>{filtrar(e) }}>Ver Todos</a></li>                 
                </ul>
            </div>
            <style jsx="true">{`
                .filtros{
                    width:15%;
                    min-width:200px;
                }
                .filtros h2{
                    font-size:1.5rem;
                }
                .filtros ul{
                    padding:0;
                    list-style:none;
                }
                .filtros ul li{
                    padding: 5px 0;
                    border-bottom: 1px solid lightgray;
                }
                .filtros ul li a:hover{
                    color: ${color}
                }
                @media only screen and (max-width: 727px) {
                    .filtros{
                        width:100%;
                    }
                    .filtros ul{
                        padding:0;
                        list-style:none;
                        display:flex;
                        flex-wrap:wrap;
                        align-items:center;
                    }
                    .filtros ul li{
                        flex: 1 1 30%;
                        border: solid 1px;
                        margin: 2px;
                        text-align:center;
                        align-items:center;

                    }
                    .filtros ul li a{
                        white-space:nowrap;
                    }
                }
                @media only screen and (min-width: 768px) and (max-width: 960px){
                    .filtros {
                        width: 99%;
                    }
                    .filtros ul {
                        padding: 0;
                        list-style: none;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                    .filtros ul li {
                        flex: 1 1 30%;
                        border: solid 1px;
                        margin: 2px;
                        text-align: center;
                        align-items: center;
                    }
                }
            
            `}</style>
        </>
    )
};
