import {useContext} from 'react';
import {AppContext} from '../appcontext';
import {Link} from 'react-router-dom';

export default function Proyectos() {
    const {color, proyectos} = useContext(AppContext);
    let contador = 0;

    return(
        <>
        <h2>Algunos proyectos</h2>
        <div className="proyectos">
            {
                proyectos.map(e=>{
                    contador++;
                    if (contador < 5) {
                    return (
                        <div className="card" key={e.id}>
                            <Link to={'/portafolio/'+e.id}>
                                <h3>{e.nombre}</h3>
                                <img  src={e.imagenes[0].ruta} alt={e.nombre}/>
                            </Link>
                        </div>)
                        
                    }
                    
                    return '';
                    
                    

                })
            }
        </div>
        <Link to="/portafolio" className="btn">Ver portafolio</Link>
        <br/>
        <style jsx="true">{`
                div.proyectos{
                    width:100%;
                    display:flex;
                    flex-wrap:wrap;
                    justify-content: space-between;
                }
                div.proyectos .card{
                    flex-basis:22%;
                    margin:20px;
                    padding:10px;
                    display:flex;
                    flex-direction:column;
                    text-align:center;
                    box-shadow: 0 16px 28px #80808047;
                    border-radius:5px;
                    cursor:pointer;
                }
                div.proyectos .card:hover{
                    background:${color}54;
                }
                div.proyectos img{
                    width:100%;
                }
                div.proyectos .card h3{
                    font-weight: 100;
                    margin: 2px;
                }
                @media only screen and (max-width: 727px) {
                
                    div.proyectos .card{
                        max-width: 39%;
                        min-width: 132px;
                        flex-basis:38%;
                        padding:0;
                    }
                }
              }
            `}</style>
        </>
    )
};
