import { useContext, useEffect } from "react";
import { AppContext } from "../appcontext";

export default function Contenido() {
  const { color } = useContext(AppContext);

  useEffect(() => {
        document.querySelector('html').scrollTop = 0;
  }, []);

  return (
    <>
      <div className="contenido">
        <div className="about-me">
          <h2 className="title">Mi primer sistema</h2>
          <div className="descripcion">
            <p>
              Desarrolle mi primer sistema a penas cuando conseguí mi primer
              empleo. Esto fue en un almacén de provisiones cuando entre como
              despachador; ya para ese entonces yo había aprendido a programar,
              pero no tenia nada de experiencia.
            </p>

            <p>
              A los pocos meses noté que habían procesos que en ocasiones se
              volvían un problema, y es que cuando el despachador terminaba de
              atender al cliente, debía de ir a caja para decir cuanto debían
              cobrar al mismo, esto era un verdadero problema ya que en
              ocasiones al haber tantos clientes el despachador no podía ir a
              caja, y al final la cajera cobraba lo que el cliente le decía o lo
              que escuchaba cuando el despachador hacia el intento de vocear el
              monto. Este problema afectaba mucho, ya que algunos clientes se
              ofendían cuando el despachador era que iba a decir cuanto le
              cobrarían, se sentían como que no le dábamos confianza.
            </p>
            <p>
              Viendo esa situación me acerque a los dueños y les propuse
              desarrollar un sistema para brindar mejor servicio, a lo cual
              ellos respondieron positivamente. Así que, cree un sistema que
              permitía a los despachadores ir sumando desde su teléfono todo lo
              que el cliente pedía, y al final con solo dar a un botón el monto
              le llegaba a caja. Fueron días muy emocionantes ya que los
              clientes se quedaban esperando que el despachador fuera con él a
              caja y cuando se le decía que ya solo tenia que pasar por caja,
              muchos se sorprendían y comenzaban a reírse y decir !oh pero este
              almacén esta moderno! y justo ahí decían que es que yo había
              creado un sistema... muy emocionante! Luego de eso al sistema se
              le agregaron mas funciones, como control de mercancía faltantes,
              recompensa al despachador que mas vendía, calculadora propia,
              reportes, etc. A los seis meses dejé de laborar en el almacén;
              cuando me fui deje a los chicos compañeros míos despachando por el
              sistema.
            </p>
          </div>
          <img className="ocultar" src="assets/img/foto2.jpeg" alt="Foto de Ronald De Leon" />
        </div>

        <div className="about-me" id="reto-mas-dificil">
          <h2 className="title">Mi reto más difícil </h2>
          <img
            src="assets/img/proyectos/sistema-ecommerce.png"
            alt="Foto de Ronald De Leon"
            style={{width: '50%'}}
          />
          <div className="descripcion">
            <p>
              Uno de los retos mas difíciles para mi fue el de crear un sistema
              ecommerce, tal vez no por las series de complicaciones que
              conlleva hacerlo, sino por mi poca experiencia de ese momento y la
              gran exigencia del proyecto. Este sistema era el centro de un
              proyecto llamado mi almacén, el cual consistía en asociar a
              personas con el fin de que todos los socios pudiesen comprar los
              productos y alimentos de sus casas vía el ecommerce.
            </p>

            <p>
              Fue toda una experiencia, tuve que crear todo desde cero sin ayuda
              de framework o librería, todo un combo, tenia una tienda online,
              un cms, un area donde se administraban los socios y los niveles ya
              que era un proyecto donde los socios ganaban cada vez que un socio
              registrado por debajo de el compraba. Tuve que programar al
              sistema para que repartiese la ganancia de cada socio. No podían
              haber errores!
            </p>
            <p>
              Ese proyecto me ayudo a superar mis limites. Recuerdo que en una
              ocasión criticaron la tienda online, alegando a que no era muy
              visual o agradable, y decidí mejorar toda la apariencia, jeje, no
              realmente no a que hora empece, pero ya para las 4 de la mañana
              había cambiado todo el frontend (parte visual) de la tienda y se
              lo envié al equipo lo cual le agrado mucho.
            </p>
          </div>
        </div>

        <div className="about-me" id="mas">
          <h2 className="title">Más de mi</h2>
          <div className="descripcion">
            <p>
              A parte de programar otra de las cosas que me gusta es ir a la
              iglesia en la cual soy músico, me encanta tocar la batería, la
              conga, la Güira y tambora. Obviamente, no todo a la vez. El piano
              también me gusta, por lo cual esta en mis planes aprender a
              tocarlo. Otra cosa que también he me ha encantado al hacerlo, es
              el de enseñar a programar a amigos y conocidos, realmente lo he
              disfrutado.
            </p>
          </div>
          <img src="assets/img/foto3.jpg" alt="Foto de Ronald De Leon" />
        </div>
      <br/>
      <br/>
      <br/>
      </div>
      <style jsx="true">{`
        div.contenido {
          display: flex;
          width: 85%;
          flex-wrap: wrap;
          min-height: 685px;
          align-content: flex-start;
        }

        div.contenido img {
          border-radius: 10px;
          width: 27%;
          max-width: 411px;
          box-shadow: 0px 13px 30px 0px ${color};
          border: solid ${color};
        }
        div.contenido h2 {
          width: 100%;
          font-size: 2p;
          margin-left: 15px;
        }
        div.contenido .card h3 {
          font-weight: 100;
          margin: 2px;
        }
        .about-me {
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
        .about-me .title {
          margin:0;
          margin-top: 40px;
          flex: 1 1 100%;
        }
        .about-me .descripcion {
          margin: 0;
          flex: 1 1 10%;
          text-align: justify;
          min-width:300px;
          line-height: 23px;
        }
        @media only screen and (max-width: 727px) {
          div.contenido {
            width: 100%;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: space-between;
          }
          div.contenido img {
            flex-basis: 100%;
          }
          .ocultar{
              display:none;
          }
        }
        @media only screen and (min-width: 768px) and (max-width: 960px) {
          div.contenido {
            width: 100%;
          }
          div.contenido img {
            flex: 1 1 100%;
            max-heigth:300px;
          }
          .about-me .descripcion {
            min-width:500px;
          }
          .ocultar{
            display:none;
        }
        }
      `}</style>
    </>
  );
}
