import {NavLink}  from 'react-router-dom';
import {useContext} from 'react';
import {AppContext} from './appcontext';
import Sociales from './sociales';


export default function Nav(){

    const {color} = useContext(AppContext);

    const mostrarMenuMovil = ()=>{
        const menu = document.querySelector('.flotante');
        menu.style.display = 'inherit';
    }
    
    const ocultarMenuMovil = ()=>{
        const menu = document.querySelector('.flotante');
        menu.removeAttribute('style');
    }

    return (
        <>
            <nav>
                <div className="logo">
                    <Sociales /> Ronald De Leon
                </div>
                <button className="btn-menu" onClick={mostrarMenuMovil}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="flotante" onClick={ocultarMenuMovil}>
                    <ul>
                        <li>
                            <NavLink activeClassName="active" exact to="/">
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/portafolio">
                                Portafolio
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/certificados">
                                Certificados
                            </NavLink>
                        </li>  
                        <li>
                            <NavLink activeClassName="active" to="/sobre-mi">
                                Sobre mí
                            </NavLink>
                        </li>                                           
                        <li>
                            <NavLink activeClassName="active" to="/contacto" className="contac">
                                Contacto
                            </NavLink>                                        
                        </li>
                        
                    </ul>
                </div>
            </nav>

            <style jsx="true">{`
                    nav{
                        width:100%;
                        display: flex;
                        justify-content: space-between;
                        position: fixed;
                        background: #fff;
                        top: 0;
                        padding: 7px 10px;
                        flex-wrap:wrap;
                        z-index:20;
                    }
                    nav ul{
                        min-width: 500px;
                        display: flex;
                        justify-content: space-between;
                        list-style: none;
                        padding-left:0;
                        
                    }
                    nav .btn-menu{
                        border:solid 1px #8080804d;
                        width:40px;
                        height:30px;
                        border-radius:5px;
                        position:relative;
                        background:#fff;
                        display:none;
                    }
                    nav .btn-menu span{
                        width:60%;
                        height:2px;
                        background:#808080c2;
                        left:8px;
                        position:absolute;
                    }
                    nav .btn-menu span:first-child{
                        top:10px;
                    }
                    nav .btn-menu span:last-child{
                        bottom:8px;
                    }
                    a{
                        text-decoration:none;
                        color:#000
                    }
                    a.contac, .btn{
                        background: ${color};
                        padding: 12px 31px;
                        border-radius: 25px;
                        color: #fff;
                        box-shadow: -3px 9px 22px 0 ${color}5c;
                    }
                    div.logo{
                        display:flex;
                        align-items: center;
                        flex: 0 0 30%
                    }
                    .active{
                        color:${color}
                    }

                    @media only screen and (max-width: 727px) {
                        nav {
                            border-bottom: solid 1px #80808014;
                            padding: 14px 10px !important;
                        }
                        nav ul{
                            flex-direction:column; 
                            justify-content: center;
                            align-items: center; 
                        }
                        nav ul li{
                            padding:10px;
                            border-bottom: solid 1px #fff;
                            width: 100%;
                            text-align: center;
                            padding: 10px;
                        }
                        nav ul li a.contac{
                            background:none;
                            color:#000;
                            box-shadow:none;
                        }
                        div.logo{
                            justify-content: flex-start;
                            flex: 0 0 60%;
                            padding-top:10px;
                        }
                        nav div.flotante{
                            position: fixed;
                            height: 100vh;
                            width: 100vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background:linear-gradient(45deg, ${color} -16%, white 73%);
                            top: 0;
                            left: 0;
                            // border: solid ${color};
                            display:none;
                        }
                        nav .btn-menu{
                            display:block;
                            margin-right:4px;
                        }
                    }
                  }
                
            `}</style>
        </>
    )
}