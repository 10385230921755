import {Link}  from 'react-router-dom';
import Header from '../header';
import {useContext, useEffect, useState} from 'react';
import {AppContext} from './../appcontext';


export default function Bio() {
    const {color} = useContext(AppContext);

    const [anchoFoto, setAnchoFoto] = useState(0);

    useEffect(()=>{
        const anchoColumna =  document.querySelector('div.bio .col:first-child').clientWidth;

        setAnchoFoto(Number(anchoColumna) - (Number(anchoColumna)*0.30));

        document.querySelector('.circulo').style.width = anchoFoto+'px';
        document.querySelector('.circulo').style.height = anchoFoto+'px';

    },[anchoFoto]);


    return(
        <>
        <div className="bio">
            <div className="col">
                <div className="circulo">
                    <div className="tab1">
                        <img src="assets/img/iconos/medalla.png" alt="Reconocimiento" />
                        Full Stack Developer
                    </div>
                    <div className="tab2">
                        <img src="assets/img/iconos/frontend.png" alt="Reconocimiento" />
                        Frontend Develover
                    </div>
                    <div className="tab3">
                        <img src="assets/img/iconos/backend.png" alt="Reconocimiento" />
                        Backend Develover
                    </div>  
                    <img src="assets/img/foto-web.png" className="foto-perfil" alt="Foto de perfil"/>              
                </div>
            </div>
            <div className="col align-left">
                <Header/>
            <div className="about1">
                <h2>Sobre Mí</h2>
                <p className="resumen">Desarrolle mi primer sistema a penas cuando conseguí mi primer empleo como despachador en un  almacén de provisiones; ya para ese entonces yo había aprendido a programar, pero no tenia nada de experiencia.</p>
                <p className="resumen">A los pocos meses noté que habían procesos que en ocasiones se volvían un problema...</p>
                <Link className='contact' to='/sobre-mi'>Seguir leyendo</Link>
                <Link className="btn" to='cv.pdf' target="_bank">Descargar cv</Link>
            </div>
            </div>
            <div className="about2">
                <h2>Sobre Mí</h2>
                <p className="resumen">Desarrolle mi primer sistema a penas cuando conseguí mi primer empleo como despachador en un  almacén de provisiones; ya para ese entonces yo había aprendido a programar, pero no tenia nada de experiencia.</p>
                <p className="resumen">A los pocos meses noté que habían procesos que en ocasiones se volvían un problema...</p>
                <Link className='contact' to='/sobre-mi'>Seguir leyendo</Link>
                <Link className="btn" to='cv.pdf' target="_bank">Descargar cv</Link>
            </div>
        </div>
        <style jsx="true">{`
            div.bio{
                display:flex;
                padding-top: 10vh;
                width: 90%;
                flex-wrap:wrap;
            }
            div.bio .col:first-child{
                flex: 1 1 60%;
            }   
            div.bio .col:nth-child(2){
                flex: 1 1 40%;
            }         
            div.bio img{
                width: 48px;
                margin-right: 15px;
            }
            .about2{
                display:none;
            }
            .align-left{
                padding-left:20px;
            }
            .foto-perfil{
                width: 80% !important;
                position: absolute;
                z-index: 12;
                left: 40px;
            } 
            .circulo{
                width: ${anchoFoto}px;
                height: ${anchoFoto}px;
                min-width: 300px;
                background-color: #7b68ee0d;
                border-radius: 300px;
                z-index: 10;
                box-shadow: 0px 0px 5px #7b68ee3d;
                position: relative;
                margin: auto;
            }
            .circulo::before{
                width:  calc(100% - 50px);
                height: calc(100% - 50px);
                content: '';
                background: #7b68ee24;
                position: absolute;
                border-radius: 300px;
                z-index: 9;
                left: 25px;
                top: 25px;
            }
            
            .circulo::after{
                width: calc(100% - 100px);
                height: calc(100% - 100px);
                content: '';
                background: ${color};
                position: absolute;
                border-radius: 300px;
                z-index: 9;
                left: 50px;
                top: 50px;
            }
            .circulo .tab1 {
                display: flex;
                max-width: 267px;
                min-width: 101px;
                background-color: #fff;
                z-index: 15;
                opacity: 1;
                border-radius: 18px;
                padding: 9px;
                position: absolute;
                box-shadow: 0 0 34px #8080805e;
                align-items: center;
                bottom: 51px;
                align-items: center;
                left: 149px;
                width: 250px;
            }
            
            
            .circulo .tab2{
                display: flex;
                max-width: 170px;
                background-color: #fff;
                z-index: 11;
                opacity: 1;
                border-radius: 18px;
                padding: 9px;
                position: absolute;
                box-shadow: 0 0 34px #8080805e;
                right: -50px;
                align-items: center;
                top: 54px;
            }
            
            .circulo .tab3{
                display: flex;
                max-width: 170px;
                background-color: #fff;
                z-index: 11;
                opacity: 1;
                border-radius: 18px;
                padding: 9px;
                position: absolute;
                box-shadow: 0 0 34px #8080805e;
                left: -45px;
                align-items: center;
                top: 45px;
            }
            .enlace-seguir-leyendo{

            }
            

            @media only screen and (min-width: 768px) and (max-width: 960px){
                div.about1{
                    display:none !important;
                }
                div.about2{
                    display:block !important;
                    margin-bottom:20px;
                }
                div.bio .col {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
                .contacto .info{
                    flex: 0 0 96%;
                }
                div.about2 p {
                    text-align: justify;
                }
                div.proyectos .card{
                    flex-basis: 41% !important;
                }
                div.proyectos {
                    margin-bottom: 25px;
                }
                .circulo .tab1 {
                    left: 49px;
                    max-width: 212px;
                    bottom: 10px;
                }
                .circulo .tab2 {
                    top: 25px;
                    right: -78px;
                }
                .circulo .tab3 {
                    top: 11px;
                    left: -52px;
                }
                .foto-perfil{
                    left: 29px;
                }
            }
            @media only screen and (max-width: 727px) {
                
                    div.bio{
                        text-align:center;
                        width:100vw;
                        padding-top:10px;
                    }
                    .tab1, .tab2, .tab3{
                        max-width:90px !important;
                        flex-wrap:wrap;
                        justify-content:center;
                    }
                    .tab1{
                        left: 63px !important;
                        bottom: 5px !important;
                        max-width: 200px !important;
                    }
                    .tab2{
                        right: -10px !important;
                    }
                    .tab3{
                        left: -21px !important;
                    }
                    div.bio img{
                        width: 30px;
                        margin-right: 15px;
                        left: 24px;
                        
                    }
                    .about1 .resumen, about2 .resumen{
                        text-align: justify;
                        padding-right: 10px;
                    }
                }
              }
        `}</style>
        </>
    )    
};
