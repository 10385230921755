import Location from "../assets/img/iconos/location.png";
import Phone from "../assets/img/iconos/phone.png";
import Correo from "../assets/img/iconos/correo.png";
import { useContext, useState } from "react";
import { AppContext } from "./appcontext";

export default function Contacto() {
  const { color } = useContext(AppContext);

  const [formEnviado, setformEnviado] = useState(false);
  const [enviando, setEnviando] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setEnviando(true);
    let data = new FormData(event.target);
    data.set(
      "message",
      `Nombre:${data.get("nombre")}, Asunto:${data.get(
        "asunto"
      )}, message:${data.get("message")}`
    );

    fetch(event.target.action, {
      method: "post",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        event.target.reset();
        setformEnviado(true);
      })
      .catch((error) => {
        //   console.log(error);
      });
  };

  return (
    <>
      <h2 className="contacto">Contacto</h2>
      <p className="contacto-descripcion">
        Puesdes ponerte en contacto conmigo
      </p>
      <div className="contacto">
        <div className="info">
          <div>
            <div className="fondo">
              <img src={Location} alt="Icono de location" />
            </div>
            <div>
              <h3>Ubicacion</h3>
              <p>Santo Domingo,Rep. Dom.</p>
            </div>
          </div>
          <div>
            <div className="fondo">
              <img src={Phone} alt="Icono de location" />
            </div>
            <div>
              <h3>Contacto</h3>
              <p>829 363 2762</p>
            </div>
          </div>
          <div>
            <div className="fondo">
              <img src={Correo} alt="Icono de location" />
            </div>
            <div>
              <h3>Correo</h3>
              <p>301294r@gmail.com</p>
            </div>
          </div>
        </div>

        {formEnviado ? (
          <p className="msg-enviado">
            Gracias por escribirme! Respondere lo mas pronto posible.
          </p>
        ) : (
          <form
            onSubmit={handleSubmit}
            action="https://formspree.io/f/mjvjyalj"
          >
            <input
              autoComplete="off"
              type="text"
              name="nombre"
              placeholder="Nombre"
            />
            <input
              autoComplete="off"
              type="email"
              name="email"
              placeholder="Correo"
            />
            <input
              autoComplete="off"
              type="text"
              name="asunto"
              placeholder="Asunto"
            />
            <textarea
              name="message"
              cols="30"
              rows="10"
              placeholder="Mensaje"
            ></textarea>
            <div className="form-footer">
              {enviando ? (
                <p>Enviando...</p>
              ) : (
                <button className="btn">Enviar</button>
              )}
            </div>
          </form>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
      <style jsx="true">{`
        div.contacto {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0 20px;
        }
        div.contacto div.fondo {
          background: ${color};
          border-radius: 5px;
        }
        div.contacto div.fondo img {
          filter: invert(1);
        }
        .contacto-descripcion {
          max-width: 800px;
        }
        p.msg-enviado {
          margin: 115px;
          flex: 0 0 59%;
        }
        div.contacto input[name="nombre"],
        input[name="email"] {
          width: 47%;
          margin: 10px;
          padding: 15px;
          background: #f1f1f1;
          border: none;
          outline: none;
          border-radius: 3px;
        }
        div.contacto input[name="asunto"] {
          margin: 10px;
          padding: 15px;
          width: 96%;
          background: #f1f1f1;
          border: none;
          outline: none;
          border-radius: 3px;
        }
        div.contacto textarea[name="message"] {
          width: 96%;
          margin: 0 10px;
          background: #f1f1f1;
          border: none;
          outline: none;
          border-radius: 3px;
        }
        div.contacto .info > div {
          display: flex;
          margin: 20px 0;
          box-shadow: 0 0 15px lightgrey;
          border-radius: 5px;
        }
        div.contacto .info > div h3 {
          margin: 16px 0 0 18px;
        }
        div.contacto .info > div p {
          margin: 5px 0 0 18px;
        }
        h2.contacto {
          margin: 0;
          padding: 0;
        }
        .form-footer {
          text-align: center;
          padding-top: 10px;
        }
        .form-footer .btn {
          width: 200px;
        }
        @media only screen and (max-width: 727px) {
          div.contacto input[name="nombre"],
          div.contacto input[name="email"] {
            width: 96%;
          }
          div.contacto .info {
            flex-basis: 100%;
          }
        }
      `}</style>
    </>
  );
}
