import {useContext} from 'react';
import {AppContext} from './appcontext';

export default function Sociales() {
    
    const {color} = useContext(AppContext);

    return (
        <>
            <div className="socials">
                <div className="redes">
                    <a href="https://www.linkedin.com/in/ronald-de-le%C3%B3n-severino-780489197" target="_bank">
                        <img src="/assets/img/linkedind.png" alt="Perfil de linkeding"/>
                    </a>
                </div>
                <div className="redes">
                    <a href="https://github.com/ronald3012" target="_bank">
                        <img src="/assets/img/github.png" alt="Perfil de github"/>
                    </a>
                </div>                                
            </div>
            <style jsx="true">{`
                .redes{
                    width:20px;
                    height:20px;
                    border-radius:2px;
                    background:${color};
                    margin-left:5px;
                }
                .redes img{
                    width:100%;
                    filter: invert(1);
                }
                .socials{
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                }
            `}</style>
        </>
    )
};
