import {Link,useHistory,useParams}  from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {AppContext} from './../appcontext';
import Slider from './slider';


export default function DetallesPortafolio(ho) {

    const history = useHistory();

    const handleClick = ()=>{
      history.push("/portafolio");
    }


    let {id} = useParams();
    const {proyectos} = useContext(AppContext);
    const proyecto = proyectos.filter(p=> parseInt(p.id) === parseInt(id))[0]
    
    useEffect(() => {
        document.querySelector('html').scrollTop = 0;
    }, []);


    const comprobarSiguienteProyecto = proyecto => proyectos.find(p => p.id === Number(id)+1);

    return(
        <>
            <div className="contenedor">
                <Slider proyecto={proyecto} />
                <div className="descripcion">
                    <h1>{proyecto.nombre}</h1>
                    <p>{proyecto.descripcion}</p>
                    <button className="btn" onClick={handleClick}>Volver atras</button>
                    {
                        comprobarSiguienteProyecto() && <Link className="btn" to={'/portafolio/'+(parseInt(id)+1)}>Siguiente proyecto &gt; </Link>
                    }
                </div>
            </div>
            <style>{`
                div.contenedor{
                    display:flex;
                    width: 100%;
                    flex-wrap:wrap;
                }
                div.contenedor div.slider img{
                    width:100%;
                }
                div.contenedor div.slider{
                    flex: 1 1 50%;
                }
                div.descripcion {
                    flex: 1 1 47%;

                }
                div.descripcion button.btn{
                    margin-right:20px;
                }
                div.descripcion p {
                    line-height: 23px;
                    text-align: justify;
                    font-weight: 300;
                    padding-right: 14px;
                }
                nav{
                    padding: 23px 10px;
                }
                @media only screen and (max-width: 727px) {
                    .btn {
                        padding: 12px 11px;
                        border-radius: 25px;
                        color: #fff;
                    }
                }

            
            `}</style>
        </>
    )
};
