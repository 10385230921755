import {useContext} from 'react';
import {AppContext} from './../appcontext';

export default function Filtros() {

    const {color} = useContext(AppContext);


    return (
        <>
            <div className="filtros">
                <h2>Etapas</h2>
                <ul>
                    <li id="primer-sistema"><a href="#primer-sistema" cat="todos">Mi primer sistema</a></li>                 
                    <li id="primer-sistema"><a href="#reto-mas-dificil" cat="todos">Mi reto más difícil</a></li>                 
                    <li id="primer-sistema"><a href="#mas" cat="todos">Más de mi</a></li>                 
                </ul>
            </div>
            <style jsx="true">{`
                .filtros{
                    width:15%;
                    min-width:200px;
                }
                .filtros h2{
                    font-size:1.5rem;
                }
                .filtros ul{
                    padding:0;
                    list-style:none;
                }
                .filtros ul li{
                    padding: 5px 0;
                    border-bottom: 1px solid lightgray;
                }
                .filtros ul li a:hover{
                    color: ${color}
                }
                @media only screen and (max-width: 727px) {
                    .filtros{
                        width:100%;
                    }
                    .filtros ul{
                        padding:0;
                        list-style:none;
                        display:flex;
                        flex-wrap:wrap;
                        align-items:center;
                    }
                    .filtros ul li{
                        flex: 1 1 30%;
                        border: solid 1px;
                        margin: 2px;
                        text-align:center;
                        align-items:center;

                    }
                    .filtros ul li a{
                        white-space:nowrap;
                    }
                }
                @media only screen and (min-width: 768px) and (max-width: 960px){
                    .filtros {
                        width: 99%;
                    }
                    .filtros ul {
                        padding: 0;
                        list-style: none;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                    .filtros ul li {
                        flex: 1 1 30%;
                        border: solid 1px;
                        margin: 2px;
                        text-align: center;
                        align-items: center;
                    }
                }
            
            `}</style>
        </>
    )
};
