import {useContext, useEffect} from 'react';
import {AppContext} from './appcontext';

export default function CambiarColor() {

        const {color, setColor} = useContext(AppContext);

        useEffect(() => {

            let colorSelected = localStorage.getItem('color');

            if (colorSelected !== null) {
                setColor(colorSelected)
            }else{
                setColor('#003b7a')
            }
      
        })

        const cbColor = (e)=>{
            let color = e.target.value;
            setColor(color);
            localStorage.setItem('color',color)
        }

        return(
            <>
            <div className="colores">
                    <input onInput={(e)=>cbColor(e)} type="color" value={color} list="presetColors"/>
                    <datalist id="presetColors">
                        <option value="#003B7a"/>
                        <option value="#00b0ff"/>
                        <option value="#33bfa6"/>
                        <option value="#f64a57"/>
                        <option value="#f9a939"/>
                    </datalist>
            </div>
            <style jsx="true">{`
                .colores{
                    position: fixed;
                    bottom: 0;
                    left: 1px;
                    transform: rotate(180deg);
                }
                .colores input{
                    border: navajowhite;
                }
                
            `}</style>
            </>
        )    
};
