import Listado from './listado';
import Header from './header';
import Filtros from './filtros';

export default function Certificados() {

    return (
        <>
            <div className="contenedor">
                <Header />
                <Filtros />
                <Listado />
            </div>
            <style jsx="true">{`
               .contenedor{
                   width:100%;
                   display:flex;
                   flex-wrap: wrap;
               }
            `}</style>
        </>
    )
};
